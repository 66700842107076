"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var text_message_content_1 = require("./text-message-content");
var class_generator_1 = require("./class-generator");
var user_avatar_1 = require("./user-avatar");
var file_message_content_1 = require("./file-message-content");
var link_message_content_1 = require("./link-message-content");
var sticker_content_1 = require("./sticker-content");
var image_message_content_1 = require("./image-message-content");
var video_message_content_1 = require("./video-message-content");
var audio_message_content_1 = require("./audio-message-content");
var svgs_1 = require("./svgs");
var date_time_format_1 = require("./date-time-format");
var draft_messages_page_1 = require("../views/draft-messages-page/draft-messages-page");
var formats_1 = require("./formats");
var users_util_1 = require("../utils/users-util");
var video_tag_message_content_1 = require("./video-tag-message-content");
var youtube_message_content_1 = require("./youtube-message-content");
var html_message_content_1 = require("./html-message-content");
var classNamesForChatListMessage = class_generator_1.classNamesGeneratorFor(function (add) {
    add("isCurrentUser", React.createElement("div", { className: "bg-silver" }));
    add("isEditing", React.createElement("div", { className: "bg-washed-yellow" }), React.createElement("div", { className: "bg--light-gray--hover" }));
}, React.createElement("div", { className: "ph4 pv3 relative hide-child w-100" }));
var classNamesForToolButton = class_generator_1.classNamesGeneratorFor(function (add) {
    add("disabled", React.createElement("div", { className: "shadow-elevate-inside" }), React.createElement("div", { className: "pointer" }));
    add("small", React.createElement("div", { className: "pt2 ph2" }), React.createElement("div", { className: "pv2 ph3" }));
    add("noBackground", React.createElement("div", { className: "bg-none" }), React.createElement("div", { className: "bg-true-light-silver" }));
    add("hoverWhite", React.createElement("div", { className: "hover-white" }), React.createElement("div", { className: "hover-light-blue" }));
}, React.createElement("div", { className: "dib br2 b--light-gray ba child" }));
function ToolButton(props) {
    return React.createElement("div", { className: classNamesForToolButton(props), onClick: props.onClick, onKeyDown: props.onKeyDown, title: props.popUp, tabIndex: props.tabIndex, style: props.style }, props.children);
}
exports.ToolButton = ToolButton;
function ChatListMessage(props) {
    var message = props.message;
    var createdTheMessage = props.createUser && (props.createUser.id === props.currentUser.id);
    var inTraining = props.inTraining;
    var isCustomer = props.createUser && props.createUser.attributes.user_type === "Customer";
    var isAdministrator = props.currentUser.attributes.user_type === "Administrator";
    var isDraftMessageInReview = props.draftMessageState === "in_review";
    var canDeleteDraftMessage = isDraftMessageInReview && (createdTheMessage || users_util_1.isAtLeastA(props.currentUser, "Supervisor"));
    var canDelete = (canDeleteDraftMessage || (isAdministrator && !inTraining) || ((createdTheMessage && !inTraining) || isCustomer)) && !props.externalRoom && message.id && props.onDelete;
    var canEdit = props.onStartEdit && props.onFinishEdit && props.onCancelEdit && !props.externalRoom && message.attributes.kind === "text" && message.id && ((isAdministrator && !inTraining) || (createdTheMessage &&
        (isDraftMessageInReview || !inTraining)));
    var canCopy = props.message.attributes.kind === "text" || props.message.attributes.kind === "link";
    var canResolve = isDraftMessageInReview && props.onToggleAddCommentModal && props.isAtleastASupervisor;
    var canShowPermalink = props.canShowPermalink ? true : false;
    var focusedMessageClassName = classNamesForChatListMessage(props);
    focusedMessageClassName += " outline-0";
    if (props.focusedMessageId && (message.id === props.focusedMessageId)) {
        focusedMessageClassName += " ba bw2 b--light-red";
    }
    return React.createElement("div", { className: focusedMessageClassName, key: "chat-list-message-" + props.message.id, tabIndex: -1, id: "messages-" + message.id },
        React.createElement("div", { className: "fr test--message" },
            canDelete &&
                React.createElement(ToolButton, { onClick: function () { return props.onDelete(message.id); }, className: "test--message-delete-button" },
                    React.createElement(svgs_1.SVGDelete, { width: 16, height: 16 })),
            canEdit &&
                React.createElement(ToolButton, { onClick: function () { return props.onStartEdit(message.id); }, className: "test--message-edit-button" },
                    React.createElement(svgs_1.SVGEdit, { width: 13, height: 16 })),
            canCopy &&
                React.createElement(ToolButton, { className: "dib", onClick: function () { return props.copyTextToClipBoard(message.id); } },
                    React.createElement(svgs_1.SVGCopy, { width: 16, height: 16 })),
            canResolve &&
                React.createElement(draft_messages_page_1.ResolveButton, { onClick: function () { return props.onToggleAddCommentModal(message.id); }, language: props.lang }),
            canShowPermalink &&
                React.createElement(ToolButton, { onClick: function () { return props.onGoToMessage(); } },
                    React.createElement(svgs_1.SVGPermalink, { width: 16, height: 16 }))),
        React.createElement("div", { className: "dt" },
            React.createElement("div", { className: "dtc pr3" },
                React.createElement(user_avatar_1.UserAvatar, { className: "w3 h3", user: props.createUser, appSettings: props.appSettings, tenant: props.tenant })),
            React.createElement("div", { className: "dtc v-top lh-copy mid-gray" },
                React.createElement("div", { className: "f5" },
                    props.createUser ? React.createElement("span", { className: "b mr2 f4 dark-gray " + (isCustomer ? "hover-underline pointer color-inherit no-underline" : ""), onClick: function () {
                            isCustomer && props.openEditCustomerModal(props.createUser && props.createUser.id);
                        } },
                        React.createElement(formats_1.FormatName, { user: props.createUser })) : null,
                    React.createElement(date_time_format_1.DateTimeFormat, { unixTime: message.attributes.created_at_unix }),
                    "( ",
                    message.attributes.created_at_unix,
                    " )"),
                (function () {
                    switch (message.attributes.kind) {
                        case "text":
                            return React.createElement(text_message_content_1.TextMessageContent, { message: message, key: "text-message-" + message.id, highlightedText: props.highlightedText, onChange: props.onChange, isEditing: props.isEditing, onCancelEdit: props.onCancelEdit, onFinishEdit: props.onFinishEdit, curEditingMessageText: props.curEditingMessageText, language: props.lang });
                        case "file":
                            return React.createElement(file_message_content_1.FileMessageContent, { message: message, startFileMessageShare: props.startFileMessageShare });
                        case "image":
                            return React.createElement(image_message_content_1.ImageMessageContent, { message: message });
                        case "link":
                            return React.createElement(link_message_content_1.LinkMessageContent, { message: message, settings: message.attributes.settings });
                        case "video":
                            return React.createElement(video_message_content_1.VideoMessageContent, { message: message });
                        case "video_tag":
                            return React.createElement(video_tag_message_content_1.VideoTagMessageContent, { message: message, language: props.lang });
                        case "youtube":
                            return React.createElement(youtube_message_content_1.YoutubeMessageContent, { message: message, language: props.lang });
                        case "audio":
                            return React.createElement(audio_message_content_1.AudioMessageContent, { message: message });
                        case "sticker":
                            return React.createElement(sticker_content_1.StickerContent, { settings: message.attributes.settings });
                        case "html":
                            return React.createElement(html_message_content_1.HtmlMessageContent, { message: message, language: props.lang });
                    }
                })())));
}
exports.ChatListMessage = ChatListMessage;
