"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var widget_state_1 = require("../reducers/widget-state");
var bisect_1 = require("../../utils/bisect");
var user_bubble_1 = require("../../components/user-bubble");
var unread_message_pill_1 = require("../../components/unread-message-pill");
var room_list_reducer_1 = require("../reducers/room-list-reducer");
var layouts_1 = require("../../components/layouts");
var time_format_1 = require("../../components/time-format");
var I18n_1 = require("../../I18n");
var scroll_bar_container_1 = require("../../components/scroll-bar-container");
var scroll_utils_1 = require("../../utils/scroll-utils");
var complete_request_reducer_1 = require("../../reducers/complete-request-reducer");
var initial_loading_reducer_1 = require("../reducers/initial-loading-reducer");
var fake_loading_1 = require("../../components/fake-loading");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var color_editable_1 = require("../../components/color-editable");
var memoizers_1 = require("../../utils/memoizers");
function messageSummary(message, user, loggedInUser) {
    if (!message || !user)
        return "";
    var language = loggedInUser.attributes.language;
    var messageSummaryString = "";
    if (user) {
        if (user.id === loggedInUser.id) {
            messageSummaryString += I18n_1.i18n.widgetLastMessageReferToCustomer[language] + ": ";
        }
        else {
            messageSummaryString += user.attributes.username + ": ";
        }
    }
    if (message) {
        switch (message.attributes.kind) {
            case "text":
            case "html":
            case "link":
                messageSummaryString += message.attributes.content || "";
                break;
            case "image":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryImage[language];
                break;
            case "video":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryVideo[language];
                break;
            case "video_tag":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryVideoTag[language];
                break;
            case "youtube":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryYoutube[language];
                break;
            case "file":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryFile[language];
                break;
            case "audio":
                messageSummaryString += I18n_1.i18n.widgetMessageSummaryAudioFile[language];
                break;
        }
    }
    return messageSummaryString;
}
function renderedUser(stores, autoPosts, supportingUser) {
    if (supportingUser)
        return supportingUser;
    if (!(autoPosts && autoPosts.length))
        return null;
    var userId = autoPosts.slice(-1)[0].user_id;
    var autoMessageUser = userId && bisect_1.Indexer.getFirstMatching(stores.users.byId, [userId.toString()]);
    if (autoMessageUser)
        return autoMessageUser;
    return null;
}
var RoomList;
(function (RoomList) {
    function render(dispatch) {
        var dispatcher = {
            goToRoomClick: function (roomId) { return dispatch(room_list_reducer_1.loadRoom(roomId)); },
            gotToRoomKeyDown: function (e, roomId) { return key_navigation_utils_1.isEnterOrSpace(e) && dispatch(room_list_reducer_1.loadRoom(roomId)); },
            createNewRoom: function () { return dispatch(room_list_reducer_1.createNewRoom()); },
            createNewRoomKeyDown: function (e) { return key_navigation_utils_1.isEnterOrSpace(e) && dispatch(room_list_reducer_1.createNewRoom()); },
            loadOldRooms: function (e) {
                if (scroll_utils_1.isAtBottomOf(e.currentTarget)) {
                    dispatch(room_list_reducer_1.loadOldRooms());
                }
            }
        };
        return memoizers_1.memoizeBySomeProperties({
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            loadingPagedRequests: widget_state_1.WidgetV2State.initialState.loadingPagedRequests,
            fakeWidget: widget_state_1.WidgetV2State.initialState.fakeWidget,
            roomListRows: widget_state_1.WidgetV2State.initialState.roomListRows,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            header_title: widget_state_1.WidgetV2State.initialState.header_title,
            app_settings: widget_state_1.WidgetV2State.initialState.app_settings,
            v2_time_format: widget_state_1.WidgetV2State.initialState.v2_time_format,
            create_room_button_text: widget_state_1.WidgetV2State.initialState.create_room_button_text,
            auto_posts: widget_state_1.WidgetV2State.initialState.auto_posts,
            stores: widget_state_1.WidgetV2State.initialState.stores,
            thisTenant: widget_state_1.WidgetV2State.initialState.thisTenant,
        }, function (state) {
            var language = state.loggedInUser.attributes.language;
            var isLoadingOldRooms = complete_request_reducer_1.areRequestsStillLoading(state.loadingPagedRequests, [initial_loading_reducer_1.loadCustomerRoomsRequestName]);
            return React.createElement(layouts_1.Column, { stretchColumn: true, className: "w-100 bblr3 bbrr3 dt test--room-list" },
                React.createElement(scroll_bar_container_1.ScrollBarContainer, { colors: state.colors, inEditMode: state.fakeWidget.inEditMode },
                    React.createElement("div", { className: "pl3 pv3 overflow-y-scroll", onWheel: dispatcher.loadOldRooms, onScroll: dispatcher.loadOldRooms },
                        state.roomListRows && state.roomListRows.map(function (row) {
                            var room = row.room;
                            var member = row.member;
                            var supporter = row.supporter;
                            var lastMessage = row.lastMessage;
                            var lastMessageUser = row.lastMessageUser;
                            var room_settings = room && room.attributes.settings;
                            var aiflg = (room_settings && room_settings.ai_chat) || false;
                            var user = aiflg ? null : renderedUser(state.stores, state.auto_posts, supporter);
                            return React.createElement("div", { className: "w-100 fr pv1 pr2 test--room-list-room-" + row.room.id, key: "room-list-" + row.room.id },
                                React.createElement(color_editable_1.ColorEditable, { className: "h3_75rem pointer br2 pa1 pl3 mh1 shadow-hover", colors: state.colors, editedColors: {
                                        backgroundColor: "room_list_room_background",
                                        color: "room_list_room_text",
                                        outlineColor: "outline",
                                    }, tabIndex: 0, role: "button", onClick: function () { return dispatcher.goToRoomClick(room.id); }, onKeyDown: function (e) { return dispatcher.gotToRoomKeyDown(e, room.id); } },
                                    React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                                        React.createElement("div", { className: "fl dib pv1" },
                                            member &&
                                                React.createElement(unread_message_pill_1.UnreadMessagePill, { colors: state.colors, className: "dib absolute z-5 left-4 nl3", relative: true, unreadCount: member.attributes.unread_message_count }),
                                            React.createElement(user_bubble_1.UserBubble, { user: aiflg ? null : renderedUser(state.stores, state.auto_posts, supporter), showStatus: true, medium: true, tenant: state.thisTenant })),
                                        React.createElement("div", { className: "absolute w-85 h3 f4 fw1 dib mt2 pt1 pl2 pr3 tracked-light-s" },
                                            React.createElement("div", { className: "flex h2 justify-between pv1" },
                                                React.createElement("div", { className: "truncate" }, (user && aiflg === false) ? user.attributes.username : aiflg === false ? (state.header_title || (state.app_settings && state.app_settings.name || "")).toLocaleUpperCase() : ""),
                                                React.createElement(time_format_1.TimeFormat, { className: "truncate", timeFormat: state.v2_time_format, unixTime: lastMessage && lastMessage.attributes.created_at_unix })),
                                            React.createElement("div", { className: "w-100 pt2 h2 truncate word-wrap" }, messageSummary(lastMessage, lastMessageUser, state.loggedInUser))))));
                        }),
                        isLoadingOldRooms && React.createElement(fake_loading_1.FakeLoading, { colors: state.colors }))),
                React.createElement(color_editable_1.ColorEditable, { className: "absolute bottom-1 right-0 left-0 dtc v-mid w-30 center h3 f4 fw1 ba br3 pointer dim test--create-room-button", colors: state.colors, editedColors: {
                        backgroundColor: "bottom_action_button_background",
                        fill: "bottom_action_button_fill",
                        color: "bottom_action_button_fill",
                        outlineColor: "outline",
                    }, onClick: dispatcher.createNewRoom, onKeyDown: dispatcher.createNewRoomKeyDown, tabIndex: 0 },
                    React.createElement("div", { className: "truncate tc pa3" }, state.create_room_button_text ? state.create_room_button_text : I18n_1.i18n.widgetCreateRoomDefaultText[language])));
        });
    }
    RoomList.render = render;
})(RoomList = exports.RoomList || (exports.RoomList = {}));
